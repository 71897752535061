import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import sparNord from "../images/spar-nord.svg"
import interSport from "../images/intersport.svg"
import greenInstruments from "../images/greeninstruments.png"
import ford from "../images/ford.svg"
import elTeknik from "../images/br-elteknik.png"

const Sponsor = () => (
  <Layout>
    <SEO title="Sponsor" />
    <section>
    <h1>Sponsorer</h1>
      <p>
        Vi vil gerne sige en kæmpe stor TAK til vores sponsorer, som gør det muligt for os at drive foreningen!
      </p>
    </section>
    <section>
      <div className="sponsor-side">
        <img src={sparNord} alt="Spar Nord" />
        <img src={interSport} alt="Intersport" />
        <img src={ford} alt="Ford" />
        <img src={greenInstruments} alt="Green Instruments" />
        <img src={elTeknik} alt="Brønderslev El-Teknik" />
      </div>
    </section>
  </Layout>
)

export default Sponsor
